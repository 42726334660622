@import url(/src/fonts/fonts.css);

$primary-color: #e21515;
// $secondary-color: #353540;
$secondary-color: #353540;
$white: #ffffff;
$success: #11ac11;
$text-btn: #0994ff;
// $icon-color: #707070;
$icon-color: #6b6b6b;
// $border :#E0E0E0;
$border: #d4d4d4;

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  color: $secondary-color;
  font-weight: normal;
  background: #f7f7f7;
  scrollbar-width: thin;
  -moz-scrollbar-width: thin;
}

// custom scroll bar css below

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

//end

// .btn-light {
//     background: #F7F7F7 !important;
// }
.contan h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: $secondary-color;
}

p {
  font-weight: normal;
  color: $secondary-color;
}

input,
button,
select,
optgroup,
textarea {
  outline: none;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #11ac11;
  border: 2px solid #11ac11;
}

.form-control:disabled {
  background-color: #d9d9d9;
  opacity: 1;
}

input[type="radio"]:hover {
  box-shadow: 0 0 5px 0px green black inset;
}

select.form-select:focus {
  box-shadow: none;
}

.page-link:focus {
  box-shadow: none;
}

:focus-visible {
  outline: none;
}

html:not([dir="rtl"]) .page-item:first-child .page-link {
  border-radius: 4px;
  background: none;
}

table tr th td {
  font-weight: normal;
}

h1 {
  font-size: 28px;
  margin-bottom: 0px;
}

h2 {
  font-size: 24px;
  margin-bottom: 0px;
}

h3 {
  font-size: 18px;
  margin-bottom: 0px;
}

h4 {
  font-size: 22px;
  margin-bottom: 0px;
}

h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

h6 {
  font-size: 14px;
  margin-bottom: 0px;
}

.form-control {
  // padding: 7px 15px;
  border: solid 1px #d9d9d9;
  background: $white;
  // height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

.form-select {
  // height: 40px;
  font-size: 14px;
  border-radius: 4px;
}

.form-label {
  color: #2a2a39;
  font-weight: 400;
  margin: 0;
  font-size: 13px;
}

// .form-label {
// 	opacity: 0.7;
// }

.form-control:focus {
  background: $white;
}

// .btn-primary {
//     background: $primary-color;
//     font-weight: 600;
//     letter-spacing: 0.5px;
//     color: $white;
// }

// .btn-light {
//     background: #ffffff;
//     font-size: 15px;
//     font-weight: 500;
//     letter-spacing: 0;
//     color: #707070;
//     border: solid 1px #707070;
//     border-radius: 4px;
//     padding: 4px 15px;
// }

.form-check {
  margin-bottom: 15px;
}

label.form-check-label {
  // padding: 5px 10px;
  cursor: pointer;
  line-height: 0;
  vertical-align: middle;
  padding-left: 8px;
}

/* login section */
.loginWrap {
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px;
}

.loginSection {
  .card {
    max-width: 80%;
    border-radius: 10px;
    margin: 0px auto;

    h3 {
      margin: 30px 0px;
      font-size: 20px;
      text-align: center;
    }

    .form-control {
      margin-bottom: 15px !important;
    }

    .remember {
      padding: 10px 0px;
    }

    .form-check-input {
      height: 24px;
      width: 24px;
    }

    .btn-primary {
      padding: 10px;

      &:hover {
        color: $white;
        -webkit-box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
        -moz-box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
        box-shadow: -1px 1px 6px -2px rgba(0, 0, 0, 0.6);
      }
    }

    .forgotPassword {
      button {
        color: $secondary-color;
        text-decoration: none;

        &:hover {
          color: $primary-color;
          text-decoration: underline;
        }
      }
    }

    .forgot {
      color: #7070707d;
    }
  }
}

.form-check-input:focus {
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
}

/* side bar top header */
.sidebar {
  background: #353540;

  img {
    width: 56%;
  }
}

.avatar-img {
  // width: 80%;
  width: 30px;
  height: 30px;
}

/* top header bar */
.header-nav {
  .bellIcon {
    width: 18px;
  }

  .helpIcon {
    width: 20px;
  }
}
.sidebar-nav .nav-group,
.sidebar-nav .nav-group.show {
  background-color: #363540;
}
.sidebar-nav .nav-group:hover>a {
  // background-color: ;
}
.sidebar-nav .nav-item .nav-link img {
  width: 20px;
  height: 20px;
  background-color: #ffff;
  border-radius: 5px;
}
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 24px;
}
/* side bar navbar */
.sidebar-closed {
  .sidebar {
    img {
      width: 86%;
    }
  }

  .nav-item {
    .nav-link {
      justify-content: center;
    }
  }
}

.sidebar-nav {
  padding: 6px 0;
  scroll-behavior: smooth;
  -moz-scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: thin;
  -moz-scrollbar-width: thin;
  // background: $secondary-color;

  &::-webkit-scrollbar {
    width: 6px;
    height: 50px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear,
        40% 0%,
        75% 84%,
        from(#969797),
        to(#969797),
        color-stop(0.6, #969797));
  }

  .nav-item {
    margin-bottom: 4px;

    .nav-link {
      display: inherit;
      color: rgb(255 255 255 / 70%);
      margin: 0px auto;
      padding: 10px 15px;
      border-radius: 4px;
      background: none;
      display: flex;
      align-items: center;
      // justify-content: center;

      &:hover {
        background: $primary-color !important;
        // color: $white;
        // max-width: 60%;
      }

      .menu-title {
        display: none;
        padding-left: 6px;
      }
    }

    a {
      svg {
        margin-right: 12px;
      }
    }
  }

  .simplebar-content-wrapper {
    width: 96%;
    margin: 0px auto;
  }
}

// a.nav-link.active {
//     background: $primary-color !important;
// }

.sidebar-nav .nav-link.active {
  background: $primary-color !important;
  color: $white;
  // max-width: 60%;
  // display: flex;
  // justify-content: center;
}

.wrapper.d-flex.min-vh-100 {
  margin-left: 85px;
  width: calc(100% - 85px);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;

  .sidebar.sidebar-fixed {
    width: 85px;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  // .body.flex-grow-1 {
  //     width: calc(100% - 85px);
  // }
  &.sidebar-open {
    margin-left: 240px;
    width: calc(100% - 240px);

    .sidebar.sidebar-fixed {
      width: 240px;

      .sidebar-nav {
        .nav-item {
          .nav-link {
            .menu-title {
              display: block;
            }
          }
        }
      }
    }
  }
}

tbody.tableMapBody {
  height: 80vh !important;
  overflow: auto;
}

/* card header */

/* offcanvas css */
.leftOuterBox {
  position: relative;

  h5 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .arrowMoveRgiht {
    position: absolute;
    top: 55%;
    right: -15px;

    button {
      background: no-repeat;
      border: 0;
      padding: 0;
    }
  }
}

.rightOuterBox {
  position: relative;

  h5 {
    margin-bottom: 15px;
    font-size: 18px;
  }

  .arrowMoveLeft {
    position: absolute;
    top: 45%;
    left: -56px;

    button {
      background: no-repeat;
      border: 0;
      padding: 0;
    }
  }
}

.leftInputBox {
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}

.rightInputBox {
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}

/* offcanvas-sidebar */
.offcanvas {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  table {
    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }
  }

  .offcanvas-header {
    background: #d9d9d9;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    padding: 10px 20px 10px 10px;

    h5 {
      font-weight: 500;
    }
  }

  .offcanvas-body {
    overflow-y: auto;

    .tablePreview {
      height: 80vh;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  .cardTable {
    table {
      border: 1px solid $border;

      tr {
        td:first-child {
          padding-left: 0px;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;

    th {
      // min-width: 140px;
      font-family: "Poppins", sans-serif !important;
      background: #eaf0f2;
      padding: 5px 10px;
      border: solid 1px #e1e1e1;
    }

    td {
      font-family: "Poppins", sans-serif !important;
      padding: 5px;
      // border: solid 1px #e1e1e1;
    }
  }

  thead tr {
    display: table-row;
    position: relative;
  }

  tbody {
    display: block;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .definationTable {
    .card {
      overflow: hidden;
    }

    table {
      thead {

        th,
        td {
          width: 100%;
          font-size: 14px;
          font-weight: 600;
          background: #eaf0f2;

          &:first-child {
            padding-left: 40px;
          }
        }
      }

      tbody {
        tr {
          td {
            width: 100%;
            font-size: 14px;
            border: solid 1px #e1e1e1;

            span {
              width: 30px;
              display: inline-block;

              img {
                width: 20px;
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  //   tbody tr:nth-child(even) {
  //     background: #ddd;
  //   }
  .wrap {
    position: absolute;
    bottom: 20px;
    left: 48%;

    .btn-primary {
      background: $success;
      padding: 5px 10px;
      border-radius: 4px;
      border: 0;
      color: #fff;
      font-weight: normal;
    }
  }

  .mapEntryButton {
    text-align: center;
    width: 100%;
    margin: 0px auto;
    position: absolute;
    bottom: 0;
    padding-bottom: 55px;
  }
}

.OffCanvasCtm input[type="checkbox"] {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  margin-right: 7px;
}

.checkBoxHeader {
  margin-bottom: 20px;
}

.checkBoxRow {
  margin-bottom: 15px;
}

// Custom Select Option
.cstm-select-drop-down {
  select.form-select {
    white-space: break-word;

    option {
      white-space: break-word;

      p {
        display: block;
      }
    }
  }
}

/* card body right section */
.cardBody {
  .borderBtm {
    border-bottom: solid 1px $border;
  }

  .search {
    display: flex;
    justify-content: end;
    margin-bottom: -37px;
    margin-right: 132px;
    margin-top: 8px;
  }

  .term {
    border: 1px solid darkgray;
    border-radius: 4px;
    height: 28px;
  }

  .cardHeader {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -1px;
    padding-top: 6px;
    padding-bottom: 6px;

    .note {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    // input {
    // 	margin-right: 8px;
    // }

    .btn-primary {
      padding: 7px 15px;
      background-color: #fff;
      border: solid 1px #cdd4d9;
      color: #353540;
      border-radius: 60px;
      font-weight: normal;
      letter-spacing: 0;
      font-size: 14px;
      margin-left: 10px;

      &:hover {
        background: #ffffff;
        background: linear-gradient(180deg, #ffffff, #fde9e9);
        border: solid 1px #cdd4d9;
      }
    }

    // .reportTable {
    // 	.MuiDataGrid-root.MuiDataGrid-autoHeight {
    // 		background: red;
    // 	}
    // }

    .exportReport {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h3 {
        font-size: 18px;
        margin-bottom: 0px;
      }

      button {
        color: #1976d2;
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;

        &:hover {
          background: rgba(25, 118, 210, 0.04);
          text-decoration: none;
        }
      }
    }

    .refresh {
      svg {
        color: #1976d2;
      }

      button {
        color: #1976d2;
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        background: none !important;

        &:hover {
          background: rgba(25, 118, 210, 0.04);
          text-decoration: none;
        }
      }
    }



    // .mr-1 {
    // 	margin-right: 8px;
    // }

    .editIcon {
      background: no-repeat;
      border: 0;
      margin-right: 10px;
      font-size: 12px;

      img {
        width: 16px;
      }
    }

    .importBtn {
      .btn-primary {
        padding: 7px 15px;
        background-color: #e7f0ff;
        border: dotted 1px #d9d9d9;
        color: $secondary-color;
        border-radius: 4px;
        font-weight: 500;
        letter-spacing: 0;

        &:hover {
          background: #ffffff;
          background: linear-gradient(180deg, #ffffff, #f4bdbd);
          border: solid 1px #f4bdbd;
        }
      }
    }
  }

  .card {
    border-radius: 0;
    border: solid 0px #d8dbe0;

    .card-body {
      padding: 0;
      border: 0;
    }
  }
}

// data grid

.dataGrid {
  .MuiDataGrid-withBorderColor {
    border-color: rgba(224, 224, 224, 1);
    background: #fff;
  }
}

/* pagination  section*/
.paginationWrap {
  display: flex;
  justify-content: end;
  margin-top: 10px;

  ul {
    .pagination {
      float: right;
      padding-right: 70px;
    }
  }

  button {
    border: 0;
    background: none;
    font-size: 12px;

    .page-link {
      padding: 4px 12px;
      color: $text-btn;
      background: none;
      border: 0;

      .disabled {
        .page-link {
          background-color: none;
        }
      }
    }
  }
}

/* table css */
.card-body {
  table {
    tr {
      th {
        font-weight: 600;
        font-size: 12px;
      }

      td {
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}

.cardTable {
  table {
    tr {
      th {
        background: #eaf0f2;
        // font-family: 'helvetica_neue65_medium';
        font-weight: normal;
        font-size: 12px;
        font-weight: 500;
        color: $secondary-color;

        &:first-child {
          // -webkit-border-top-left-radius: 12px;
          // -moz-border-radius-topleft: 12px;
          // border-top-left-radius: 12px;
          // padding-left: 20px;
          text-align: center;
        }

        &:last-child {
          // -webkit-border-top-right-radius: 12px;
          // -moz-border-radius-topright: 12px;
          // border-top-right-radius: 12px;
          text-align: center;
        }
      }

      td {
        vertical-align: middle;
        font-size: 12px;
        color: $secondary-color;

        &:first-child {
          // padding-left: 32px;
          text-align: center;
        }

        &:last-child {
          text-align: center;
        }

        button {
          background: transparent;
          padding: 4px 8px;
          border: 0;
          margin-right: 5px;

          img {
            background: none;
            border: 0;
            width: 18px;
          }
        }

        .btn-primary {
          font-size: 12px;
          background: $white;
          border: solid 1px $text-btn;
          border-radius: 60px;
          color: $text-btn;
          margin-right: 0;
          padding: 6px 12px;

          &:hover {
            background: $text-btn;
            border: solid 1px $text-btn;
            color: #ffffff;
            box-shadow: none;
            padding: 4px 8px;
            transform: translateY(0px);
          }
        }

        // remove after check all page
        .css-bz5ng3-MuiInputBase-root-MuiInput-root::before {
          border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
        }

        .css-9otb32 {
          margin-top: 38px !important;
          margin-left: 51px !important;
        }

        // .editIcon {
        // 	background: no-repeat;
        // 	border: 0;
        // 	margin-right: 5px;

        // 	img {
        // 		width: 13px;
        // 	}
        // }

        // .deleteIcon {
        // 	background: no-repeat;
        // 	border: 0;

        // 	img {
        // 		width: 11px;
        // 	}
        // }
      }
    }
  }
}

// table row fonts icon

.fontIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-top: solid 0px #d8dbe0;
  }

  &:last-child {
    border-bottom: solid 1px #d8dbe0;
  }

  button {
    background: none;
    border: 0;

    span {
      background: none;
      border: 0;
      font-size: 20px;
      color: $icon-color;
    }
  }
}

.actionMapping {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: none;
    padding: 0px;
    border: 0;
    width: auto;
    font-size: 20px;
    margin-right: 8px;
  }

  .map_red {
    background: $primary-color;
    color: $white;
    padding: 4px 12px;
    border-radius: 60px;
    font-size: 11px;
    cursor: pointer;
    min-width: 65px;
    text-align: center;
  }

  .mapped_green {
    background: $success;
    color: $white;
    padding: 4px 12px;
    border-radius: 60px;
    font-size: 11px;
  }
}

.verifyitBtn {
  background: $secondary-color !important;
  color: $white;

  &:hover {
    color: #fff;
  }

  .small {
    font-size: small;
  }
}

.cardHeader {
  input[type="text"] {
    border: solid 1px #cdd4d9;
    padding: 4px 16px;
    border-radius: 60px;
    // margin-right: 10px;

    &:focus {
      border: solid 1px #cdd4d9;
    }

    &:hover {
      border: solid 1px #cdd4d9;
    }
  }
}

/* toggle */
.switchtoggle {
  // display: flex;
  // align-items: center;
  // justify-content: end;
  // padding: 12px;
  // margin: 0px;
  float: right;
  padding-top: 5px;
  display: flex;
  margin-left: 10px;

  span {
    margin-left: 5px;
  }

  .form-check {
    margin: 0;
  }

  .form-check-input {
    padding: 5px 16px;
    width: 25px;
    margin-right: 6px;
  }
}

.tabSection {
  margin-bottom: 15px;
  position: relative;
  padding-left: 0px;

  // &::after {
  //     content: "";
  //     border-bottom: solid 2px #D9D9D9;
  //     width: 100%;
  //     position: absolute;
  //     bottom: 0px;
  // }

  ul {
    float: left;
  }
}

.tabSection a.nav-link {
  color: $secondary-color;
  border: 0;
}

.tabSection a.nav-link.active {
  background: #eeeeee;
  border-bottom: solid 3px #e21515;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}

/* code details page */
.card :has(.card) {
  border: none;
}

.codeDetailBox {
  padding: 0px;

  .card {
    border-radius: 8px;
    padding: 16px;
    background: #fff;
    border: 0px;
    margin-bottom: 15px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    .mappedDetail {
      .mb-10 {
        margin-bottom: 12px;
      }

      b {
        padding-left: 5px;
        font-weight: 500;
      }
    }
  }
}

.splitBtn {
  button {
    background: #707070;
    border: 0;
    color: #fff;
    font-size: 20px;
    line-height: 10px;
    width: 34px;
    height: 34px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    font-weight: 600;
    border-radius: 60px;
    letter-spacing: 0;
    padding: 0;

    &:hover {
      color: #353540;
      background: #ffffff;
      background: linear-gradient(180deg, #ffffff, #f4bdbd);
      border: solid 1px #f4bdbd;
    }
  }
}

.menu-icon {
  font-size: 24px;
  vertical-align: middle;
}

.icon-view {
  color: $secondary-color;
}

.icon-split {
  color: $secondary-color;
  font-size: 30px;
  font-weight: 500;
}

.line-height-1 {
  line-height: 1;
}

.code--select {
  width: calc(100% - 45px);
}

.financialDetailsSplit {
  .code--select {
    width: calc(100% - 0px);
  }
}

.financialDetailsCanvasBox {
  .code--select {
    width: calc(100% - 0px);

    .card {
      display: flex;
      align-items: center;
      padding: 12px 8px;
      border-radius: 4px;
      margin-bottom: 0;
      background: linear-gradient(0deg,
          rgb(245 245 245) 0%,
          rgba(255, 255, 255, 1) 100%);
      max-width: 90px;
      margin-bottom: 10px;
      margin-top: 10px;

      img {
        width: 24px;
      }
    }
  }
}

.receiptTab {
  button {
    padding: 2px 10px;
    border-radius: 63px;
  }

  .tabBtn {
    background: transparent;
  }

  .MuiToggleButton-root.Mui-selected {
    background: $secondary-color;
    color: $white;

    &:hover {
      background-color: #eaf0f2;
      color: $secondary-color;
    }
  }
}

.codingTab {
  display: flex;
  gap: 10px;
}

.select--accordion .accordion-body .card img {
  width: 20px;
  margin: 0 auto 0.3rem auto;
}

.select--accordion .accordion-body .row [class*="col-md-"] {
  padding: 0 0.2rem;
}

.select--accordion .accordion-body .row {
  margin: 0 -0.5rem;
}

.select--accordion .accordion-body .card {
  padding: 0.7rem 0.2rem;
}

// .addBox {
// 	// overflow-y: scroll;
// 	// height: 95vh;
// 	// overflow-x: hidden;
// 	overflow-y: auto;
// 	overflow-x: hidden;
// }

.splitBtn {
  margin-top: 1px;
  margin-left: 15px;
}

.attachmentBtn {
  display: flex;
  justify-content: flex-end;

  .btn-primary {
    padding: 5px 20px;
    background-color: #fff;
    border: solid 1px #cdd4d9;
    color: #353540;
    border-radius: 60px;
    font-weight: 500;
    letter-spacing: 0;

    &:hover {
      background: #ffffff;
      background: linear-gradient(180deg, #ffffff, #f4bdbd);
      border: solid 1px #f4bdbd;
    }
  }
}

/*
swtich three toggle css
*/

//track height
$tkh: 20px;
//track width
$tkw: $tkh * 2;
//thumb height
$thh: $tkh - 4px;
//thumb width
$thw: $thh;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin invisible {
  -webkit-appearance: none;
  appearance: none;
}

@mixin animation {
  transition: background-color 0.25s ease-in-out;
  transition: position 0.25s ease-in-out;
}

// body {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   min-height: 100vh;
//   font-family: "Overpass";
//   font-size: .8em;
//   font-weight: bold;
// }

p {
  margin: 0;
  padding: 0;
}

// .wrapper {
//   width: 200px;
//   height: 50%;
//   display: flex;
//   justify-content: center;
//   padding: 20px 0;
// }

#custom-toggle {
  appearance: none;
  height: 24px;
  width: 48px;
  background-color: #fff;
  border-radius: 60px;
  padding: 0 2px;
  margin: 0;
  border: solid 1px #cdd4d9;
  cursor: pointer;

  &.tgl-def {
    &::-webkit-slider-thumb {
      background-color: orange;
      //@include animation;
    }
  }

  &.tgl-on {
    &::-webkit-slider-thumb {
      background-color: green;
      //@include animation;
    }
  }

  &.tgl-off {
    &::-webkit-slider-thumb {
      background-color: red;
      //@include animation;
    }
  }

  &::-webkit-slider-thumb {
    @include invisible;
    width: $thw;
    height: $thh;
    @include border-radius(25px);
  }

  &:focus {
    outline: none;
  }
}

.rangeActive {
  background-color: green;
}

.dataGridHeader {
  background-color: #eaf0f2;
}

.itemColorCode {
  background-color: #b2d7b2;
}

.varifyColorCode {
  background-color: #cccccc;
}

.supportColorCode {
  background-color: #ff7979;
}

.codeColorCode {
  background-color: #b1d1ea;
}

// rowWrap button

.rowWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 4px;
}

.mb-10 {
  margin-bottom: 16px;
}

// this css is working temporary will be find better way
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  box-shadow: none !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0;
}

.Mui-expanded.MuiAccordionSummary-root {
  min-height: 40px !important;
  border-bottom: solid 1px $border !important;
}

.Mui-expanded {
  margin: 0 !important;
}

.MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 21%), 0px 1px 1px 0px rgb(0 0 0 / 0%),
    0px 1px 3px 0px rgb(0 0 0 / 36%);
  margin-bottom: 10px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.MuiInputBase-input-MuiInput-input {
  min-width: auto;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  background: #e5f4ff;
}

.css-bz5ng3-MuiInputBase-root-MuiInput-root.Mui-disabled:before {
  border-bottom-style: none !important;
}

.css-bz5ng3-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 0 !important;
}

.css-az8st9-MuiDataGrid-root .MuiDataGrid-row {
  background: #fff;
}

.dataGridTable {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

button.page-link {
  background: transparent;
  border: 0;
}

// date picker
.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      border: solid 1px #d9d9d9;
      background: $white;
      padding: 4px 6px;
      margin-left: 15px;
      border-radius: 4px;
    }

    &::after {
      content: url(../assets/images/calendar.png);
      position: absolute;
      right: 10px;
      top: 25%;
    }
  }
}

// tarnBody
.tarnBody {
  .selectDateFilterSection {
    display: flex;
    text-align: right;
    align-items: baseline;
    justify-content: end;
    padding: 4px 0px;
  }

  .cardTable {
    border: solid 1px #d8dbe0;
    border-radius: 12px;
  }
}

// .MuiDataGrid-row,
// .MuiDataGrid-cell,
// .MuiDataGrid-cell--withRenderer {
// 	min-height: 28px !important; /* Adjust the height as needed */
// 	max-height: 28px !important;
//   }

.codeDataTable {
  height: 76vh;

  .MuiDataGrid-columnHeaders {
    min-height: inherit !important;
    font-size: 12px !important;
  }

  .MuiDataGrid-columnHeader {
    height: 22px !important;
    background: #eaf0f2;
    font-size: 12px !important;
  }

  .MuiDataGrid-root .MuiDataGrid-row {
    // min-height: 22px !important;
    // max-height: 22px !important;
    font-size: 12px !important;
  }

  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    overflow: hidden;
    white-space: nowrap;
    // min-height: 22px !important;
    // max-height: 22px !important;
    font-size: 12px;
  }

  .MuiDataGrid-cellContent {
    font-size: 12px;
  }

  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
  }
}

// tooltip more
.menuiconHover {
  // justify-content: space-between;
  justify-content: left;
  gap: 7px;

  .MuiDataGrid-root {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
  }

  .card {
    padding: 10px;
    min-width: 80px;
    max-width: 80px;
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    background: linear-gradient(180deg, #ffffff, #f9f9f9);
    border: solid 1px #e1e1e1;
    margin-bottom: 10px;

    &:hover {
      background: linear-gradient(180deg, #ffffff, #fbf3f3);
      border: solid 1px #e1e1e1;
      cursor: pointer;
    }

    .card-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 70px;
      text-align: center;
    }

    img {
      margin-bottom: 6px;
      width: 30px;
    }
  }
}

.menuNormalIcon {
  justify-content: space-around;

  .card {
    // min-width: 76px;
    // padding: 8px;
    // display: flex;
    // align-items: center;
    // justify-items: center;
    // font-size: 12px;
    // border: solid 1px #e1e1e1;
    padding: 10px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    background: #fff;
    border: solid 1px #e1e1e1;

    &:hover {
      cursor: not-allowed;
    }

    img {
      margin-bottom: 6px;
      width: 30px;
    }
  }

  .entriesWrapper {
    .card {
      align-items: flex-start;
      justify-content: flex-start;

      img {
        width: 20px;
        margin-top: 5px;
      }

      p {
        font-size: 13px;
        color: #2a2a39;
      }
    }
  }
}

.financialInstrumentSplit{

    padding: 10px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    background: #fff;
    border: solid 1px #e1e1e1;
    margin-bottom: 20px;

    &:hover {
      cursor: not-allowed;
    }
    p{
      line-height: 24px;
      font-size: 14px;
    }
    .nmt1{
      margin-top: 6px;
    }
}

// Css for ITIMS
.itim-list {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.itim-heading {
  font-size: 15px;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.add-button {
  margin-top: 20px;
  display: flex;
  margin-bottom: 40px;
  justify-content: flex-end;
}

.item-list {
  list-style-type: none;
  border: 1px solid #e5eaf2;
  border-radius: 12px 12px 12px 12px;
  padding: 0px !important;
}

.itim-item {
  padding: 5px;
  border-bottom: 1px solid #e5eaf2;
  margin-top: 10px;
}

// AG Grid with Style
.container {
  width: "100%";
  height: "100%";
}

.ag-theme-alpine {
  .ag-header-row .ag-header-row-column {
    margin-top: -6px;
  }

  .ag-cell {
    font-size: 12px;
    /* Decrease the font size of the cell */
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .ag-header-container {
    font-family: "Poppins", sans-serif;
    background: #eaf0f2;
  }

  .ag-header-cell-label {
    font-weight: 500;
    color: #353540;
  }

  .custom-grid {
    margin-left: -25px;
    margin-right: -21px;
  }
}

// border remove form AppHeader.js file
.border-0 {
  border: none !important; // Use !important to override any existing styles
}

.action-header {
  margin-left: 50px;
  /* Adjust margin-left as needed */
}

// this css of entry.js file

// ABN CSS START
.box.mb-2.abn-wrapper {
  border: 0;
  padding: 0;
}

.abn-wrapper {
  border: 0;
  padding: 0;

  .card {
    padding: 0px;
    border: 0;

    .card-body {
      padding: 0px;
    }
  }

  .subHeadingTexgt {
    font-size: clamp(14px, 3vw, 15px);
    font-weight: 500;
  }

  table {
    tbody {

      th,
      td {
        font-size: clamp(12px, 3vw, 14px);
      }
    }
  }

  .abnTabs {
    .nav-item {
      flex: 1 0;
      text-align: center;

      .nav-link {
        color: #2a2a39b3;
        font-weight: 600;
        border: none;
        margin-bottom: -2px;

        &:hover {
          border: none;
          color: #2a2a39;
        }

        &.active {
          border: none;
          color: #2a2a39;
          border-bottom: 3px solid $primary-color;
        }
      }
    }
  }


  button {
    &.btn-link {
      background-color: transparent;
      border: none;
      color: #0994ff;
      padding: 0;
      font-size: 15px;
      line-height: 1.1;

      &:hover {
        color: $secondary-color;
        background-color: transparent;
      }

      &:active {
        background-color: transparent;
        color: #0994ff;
      }
    }

    &.btn-outline-gray {
      color: #2a2a39;
      background-color: $white;
      border: 1px solid $icon-color;
    }

    &:hover {
      color: $white;
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

// ABN CSS END
// Dabitor Creaditor CSS START
.dabitorCreaditor {
  table {
    tr {
      &:last-child {
        td {
          border: none;
        }
      }
    }
  }
}

// Dabitor Creaditor CSS END
// Recipt Entry CSS START
.form-group {
  margin-bottom: 1rem;
}

.tableLastBoderNone {
  tbody {
    tr {
      &:last-child {
        td {
          border: none;
        }
      }
    }
  }
}

.reciptEntryWrapper {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      input {
        margin: 0;
        width: 100%;
        border: solid 1px #d9d9d9;
        background: #ffffff;
        font-size: 14px;
        padding: 0.375rem 0.75rem;
      }
    }
  }

  .entryImgWrapper {
    background-color: #2a2a39;
    padding: 70px;

    .entryCardImage {
      width: 100%;
      max-height: 100%;
      display: block;
      object-fit: contain;
    }
  }
}

// Recipt Entry CSS END

.mr-2 {
  margin-right: 10px !important;
}

.mtb-10 {
  margin-bottom: 16px;
  margin-top: 16px;
}

.mtb15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.transactionDefin {
  .similarTransactionsTable {
    height: auto;

    .TransactionDefinition {
      padding: 6px 0;
      scroll-behavior: smooth;
      -moz-scroll-behavior: smooth;
      overflow-y: auto;
      scrollbar-width: thin;
      -moz-scrollbar-width: thin;
      height: 320px;

      // background: $secondary-color;

      &::-webkit-scrollbar {
        width: 6px;
        height: 50px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fff;
        background-image: -webkit-gradient(linear,
            40% 0%,
            75% 84%,
            from(#969797),
            to(#969797),
            color-stop(0.6, #969797));
      }
    }

    .MuiDataGrid-root.MuiDataGrid-root--densityCompact.MuiDataGrid-withBorderColor.MuiDataGrid-root {
      max-height: 320px;
    }
  }
}

.text-right {
  text-align: right;
}

.applyCode {
  position: absolute;
  right: 18px;
}

.similarTransactionsTable {
  table {
    tr {
      th {
        background: none;
      }
    }
  }
}

.w-320 {
  width: 260px;
}

.selectCompany {
  border: solid 1px #dddddd;
  border-radius: 4px;
  margin-right: 10px;

  [class*="-control"] {
    border-color: #dddddd !important;
    box-shadow: none !important;
    border-width: 0;
  }

  input.css-mohuvp-dummyInput-DummyInput {
    border-color: #dddddd !important;
  }

  // [class*="-menu"]{

  // }
}

.pt8 {
  padding-top: 16%;
}

.selectOption {
  select {
    background: #fff;

    option {
      width: 250px !important;
      max-width: 250px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-appearance: inherit !important;
    }
  }
}

// 	.selectOption select,
// .selectOption select option {
//   width:250px !important;
//   max-width:250px !important;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   -webkit-appearance: inherit !important;

//   &:focus{ width:250px;}
// }

/* carousel */
.modal-body {
  background-color: #fff;
  // padding: 20px 0px;

  .carousel-item {
    // min-height: calc(100% - 4rem);
    height: 600px;
    overflow-y: auto;
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    // flex-wrap: wrap;
  }
}

.uploadCutomModal {
  .modal {
    --cui-modal-width: 800px;
  }
}

.modal-header {
  border-bottom: 1px solid $border;
  padding-bottom: 10px;
  padding-left: 0;
  padding-right: 0;

  h5 {
    font-weight: 500;
  }
}

.page {
  padding: 16px 0px 0px 0px;
}

.modal-content {
  border-radius: 8px;
  background: #fff;
  padding: 16px;
}

.modal-content3 {
  border: dotted 2px #a6a6a6;
  border-radius: 16px;
  background: #fff;
  padding: 15px;
}

.clearfix {
  clear: both;
}

.modal-vertical-center {
  .modal-dialog {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100% - 3.5rem);
    max-width: 50%;
  }
}

.modal-backdrop.show {
  background-color: rgba(255, 255, 255, 0.5);
  /* Semi-transparent background */
  opacity: 0.1;
  /* Adjust opacity for transparency */
}

.transactionDefination {
  background: rgba(0, 0, 0, 0.5);

  .modal-content {
    border: 0;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.modal.show.transactionDefination .modal-dialog {
  max-width: 80%;
  width: 100%;
}

.large .modal-dialog {
  max-width: 70% !important;
}

.modal.show.overflowBox .modal-dialog {
  max-width: 840px;
  width: 100%;
}
.card-body.overflow.sidebar-nav {
  height: 180px !important;
}
.overflowBox {
  background: rgba(0, 0, 0, 0.5);

  .overflow {
    max-width: 800px;
    width: 100%;
    max-height: 460px;
    overflow-y: auto;
    padding: 0px;
    margin: 0px;
  }
}

// end modal popup

// buttons

.btn-primary {
  align-items: center;
  background-color: $primary-color;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  font-size: 14px;
  padding: 8px 24px;
  min-width: 70px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: $white;
    // transform: translateY(-1px);
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: $white;
  }

  &:active {
    background-color: $white;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: $white !important;
  }
}

.btn-light {
  align-items: center;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-weight: 500;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  font-size: 14px;
  padding: 8px 24px;
  min-width: 70px;

  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
    // transform: translateY(-1px);
    background: #f3f3f3;
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }

  &:active {
    background-color: $white;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
}

// itim additim
.ulliBox {
  padding: 8px 0px;

  li {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ulliBox2 {
  li {
    padding: 0px !important;
  }
}

.mt-10 {
  margin-top: 10px;
}

// add rule
.addRule {
  .form-check-label {
    vertical-align: middle;
    margin-left: 5px;
    line-height: 25px;
  }
}

.gstCode {
  table {
    border: solid 1px #d8dbe0;
    border-radius: 2px;

    tr {
      th {
        box-shadow: none;
      }
    }
  }
}

// form checked

.form-check-input:checked {
  background-color: $success;
  border-color: $success;
}

.w-100 {
  width: 100%;
}

//attachmentdBox

.attachmentdBox {
  background: gainsboro;
  position: relative;
  padding: 10px;
  margin: 0px auto;
  overflow: hidden;

  .crossIcon {
    position: absolute;
    top: 0;
    right: 0;
    background: #0000005c;
    padding: 4px;
    border-radius: 100px;
  }

  body {
    img {
      overflow: hidden;
    }
  }
}

.box {
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  .form-check {
    margin: 0px;
    margin-bottom: 6px;
    vertical-align: super;
    justify-content: normal;

    input {
      margin-top: 3px;
    }

    label {
      padding-left: 2px;
      vertical-align: middle;
    }
  }

  h6 {
    color: $secondary-color;
    font-size: 15px;
    padding: 10px 0px 10px 0px;
    font-weight: 500;
  }
}

// import upload box

.uploadBox {
  padding: 20px;
  background-color: #f1f6f9;
  border: dotted 3px #cdd4d9;
  color: $secondary-color;
  border-radius: 8px;
  width: 90%;
  margin: 0px auto 20px auto;

  .btn-primary {
    padding: 10px 16px;
    background: inherit;
    border: 0;
    color: $secondary-color;
    border-radius: 0;
    font-weight: normal;
    letter-spacing: 0;
    font-size: 18px;

    &:hover {
      border-color: none;
      box-shadow: none;
      color: $secondary-color !important;
      // transform: translateY(-1px);
    }

    &:focus {
      border-color: none;
      box-shadow: none;
      color: $secondary-color !important;
    }

    &:active {
      background-color: inherit;
      border-color: none;
      box-shadow: none;
      color: $secondary-color !important;
    }
  }
}

// entry maped offcanvas

.reMapEntry {
  height: 28vh;
  overflow: hidden;
  overflow-y: scroll;
}

// apply btn
.applyBtn {
  .btn-primary {
    padding: 7px 15px;
    background-color: #fff;
    border: solid 1px #cdd4d9;
    color: #353540;
    border-radius: 60px;
    font-weight: normal;
    letter-spacing: 0;
    font-size: 14px;

    &:hover {
      background: #ffffff;
      background: linear-gradient(180deg, #ffffff, #fde9e9);
      border: solid 1px #cdd4d9;
    }

    &:active {
      color: #353540;
    }
  }
}

// data table grid
.rowIcon {
  vertical-align: middle;
  display: flex;

  .download {
    cursor: pointer;
    color: #0994ff;
  }

  .view {
    cursor: pointer;
    color: #707070;
  }
}

.codingDataGrid {
  height: calc(100vh - 120px);
  width: 100%;

  .MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
  .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
    background: #eaf0f2;
  }
}

.datatableGrid {
  height: calc(100vh - 120px);
  width: 100%;
  &.bank-transaction {
    height: calc(100vh - 170px);
  }
  &.autoHeightTable {
    // height: calc(100vh - 70px);
    height: auto;
    overflow: auto;
  }
  .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders {
    background: #eaf0f2 !important;
    font-size: 14px;
    font-weight: 600;
  }

  .MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
  .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"] {
    background: #eaf0f2;
  }

  button {
    background: transparent;
    // padding: 4px 8px;
    border: 0;
    margin-right: 5px;
    color: #707070;
    font-size: 24px;

    img {
      background: none;
      border: 0;
      width: 24px;
      margin-right: 10px;
    }
  }

  .btn-primary {
    font-size: 12px;
    background: $white;
    border: solid 1px $text-btn;
    border-radius: 60px;
    color: $text-btn;
    margin-right: 0;
    padding: 4px 10px;

    &:hover {
      background: $text-btn;
      border: solid 1px $text-btn;
      color: #ffffff;
      box-shadow: none;
      padding: 4px 10px;
      transform: translateY(0px);
    }
  }

  .disabled {
    font-size: 12px;
    background: none;
    border: solid 1px #898989;
    border-radius: 60px;
    color: #898989;
    margin-right: 0;
    padding: 6px 12px;
  }
}

.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders {
  background: #eaf0f2 !important;
  font-size: 14px;
  font-weight: 600;
}

.MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}

.MuiDataGrid-columnHeaders {
  background: #eaf0f2 !important;
  font-size: 14px;
  font-weight: 600;
}

// attached plugin

.image-viewer {
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: red;
    }
  }

  .img {
    outline: none;
    max-width: 100%;
    max-height: 100%;
    height: 460px;
  }
}

.dropzone-multiple-img {
  display: flex;
  flex-wrap: wrap;

  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: calc(33.33% - 8px);
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    justify-content: center;
  }

  .thumbAddSupport {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: calc(20% - 8px);
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    justify-content: center;
  }

  .thumbInner {
    display: flex;
    min-width: 0;
    // overflow: hidden;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .closeButton {
    position: absolute;
    top: -9px;
    right: -9px;
    background-color: rgba(226, 21, 21, 0.54);
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 60px;
    line-height: 1;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .thumb2 {
    display: inline-flex;
    border: 2px dashed #ccc;
    padding: 4px;
    box-sizing: border-box;
    position: relative;
    background: #fcfcfc;
    cursor: pointer;

    .drag-active {
      border-color: $success;
    }

    p {
      font-size: 16px;
      color: #cdd4d9;
      width: 100%;
      text-align: center;
      cursor: pointer;
      line-height: 5rem;
    }
  }
}

.addFileBtn {
  width: 100%;
  text-align: center;
  color: #cdd4d9;

  .addIcon {
    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  span {
    display: block;
  }
}

// modal popup design supportRequestBody

.supportRequestBody {
  padding: 15px 0px;
}

// mozilla issues fixed codeing select drop down distrub
.MuiOutlinedInput-root {
  padding: 0px 10px !important;
}

// help legends
.helpLegendsBox {
  width: 320px;
  padding: 0px 20px;

  .legendsStatusBox {
    h4 {
      font-size: 16px;
      padding: 8px 0px;
      margin: 4px 0px;
      border-bottom: solid 1px $border;
    }

    .legRow {
      display: flex;
      padding-top: 8px;

      .legRowBox {
        display: flex;
        padding: 0px;
        width: 50%;

        span {
          margin-right: 6px;
        }
      }
    }

    span {
      font-size: 20px;
    }
  }
}

.icon-menubar {
  font-size: 32px;
  cursor: pointer;
}

.MuiButtonBase-root-MuiCheckbox-root {
  padding: 0;
}

.infoDescription {
  .bgOrange {
    background: #fbead0;
    padding: 16px;
    position: relative;
    border-radius: 4px;

    .icon-info {
      position: absolute;
      top: 11px;
      right: 0;
    }
  }

  .icon-info {
    font-size: 24px;
    color: #ecb45f;
    line-height: 0;
    vertical-align: middle;
    cursor: pointer;
  }
}

// iframe
.iframeBox {
  iframe {
    width: 100%;
    height: 60vh;
  }
}

.highFreqAddRuleSplitEntryTable {
  width: 93%;
  margin: 0px auto;

  .tableHeader {
    display: flex;
    background-color: #f2f2f2;
    border-top: 1px solid rgba(224, 224, 224, 1);
  }

  .tableColumn {
    padding: 8px;
    // border-right: 1px solid #ccc;
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .bdr {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  .col-md-9.tableColumn {
    border-right: 1px solid rgba(224, 224, 224, 1);
  }

  .tableColumn:last-child {
    // border-right: none;
  }

  .tableBody {
    display: flex;
    flex-direction: column;
  }

  .tableRow {
    display: flex;
    border-left: 1px solid #ccc;
  }
}

// check box
.CheckBoxAbn {
  position: relative;

  .CheckIcon {
    position: absolute;
    top: 27px;
    right: 6px;
    cursor: pointer;
  }
}

.AutoComplete {
  margin-top: 10px;

  .MuiAutocomplete-input {
    padding: 1.5px 4px 7.5px 5px;
  }
}

.imagePreviewButton {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    border: 0;
    background: #cdd4d9;
    border-radius: 60px;
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
}

.btn-close {
  &:focus {
    border: 0;
  }
}

//css for financial instruments deatils card by shekhar
.financial-instrument {
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin: 0px 0 16px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .card-header {
      margin-bottom: 16px;
      padding-bottom: 10px;
      padding-left: 0px;
      background: none;

      h2 {
        margin: 0;
        font-size: 16px;
        padding: 0px 15px;
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding-top: 0px;
    }

    .card-details {
      // margin-bottom: 16px;
      line-height: 18px;

      p {
        margin: 4px 0;

        strong {
          font-size: 16px;
          font-weight: 500;
        }
      }

    }
  }

  button.btn.btn-primary.runBtn {
    font-size: 12px;
    background: #ffffff;
    border: solid 1px #0994ff;
    border-radius: 60px;
    color: #0994ff;
    margin-right: 0;
    padding: 4px 10px;
    border-radius: 20px;

    &:hover {
      background: #0994ff;
      border: solid 1px #0994ff;
      color: #ffffff;
      box-shadow: none;
      padding: 4px 10px;
      transform: translateY(0px);
    }

  }

  .disabled {
    font-size: 12px;
    background: none;
    border: solid 1px #898989;
    border-radius: 60px;
    color: #898989;
    margin-right: 0;
    padding: 6px 12px;
  }
}

.profileSection {
  .ml-50 {
    margin-left: 50px;
  }

  h5 {
    font-weight: 500;
    margin: 0;
  }
}

// canvas box
.canvasCardBox {
  .code--select {
    width: calc(100% - 0px);
  }

  .splitCard {
    border: solid 1px $border;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;

    .wrapRow {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-bottom: 10px;

      .form-check {
        margin-bottom: 0;
      }

      .spacer {
        width: 30%;
      }

      .inputW {
        min-width: 20px;
        width: 40px;
      }
    }

    .form-control {
      min-width: 70px;
    }

    .form-check {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .form-check-input {
      margin-top: 0;
    }

    .form-label {
      font-size: 12px;
      margin: 2px;
    }

    .size {
      .form-control {
        max-width: 70px;
      }
    }

    .card {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 4px;
      margin-bottom: 4px;

      img {
        width: 24px;
      }
    }
  }

  .codeTypeImages {
    .codingImageCard {
      width: 100px;
    }
  }

  .splitCardAc {
    margin-bottom: 10px;

    .codeSelect {
      width: 100%;
    }

    .card {
      display: flex;
      align-items: center;
      padding: 12px 8px;
      border-radius: 4px;
      margin-bottom: 0;
      background: linear-gradient(0deg,
          rgb(245 245 245) 0%,
          rgba(255, 255, 255, 1) 100%);
      max-width: 90px;
      margin-bottom: 10px;

      img {
        width: 24px;
      }
    }
  }
}

.financial-text {
  font-size: 18px;
  margin-bottom: 5px;
}

// profile detail page
.m-atuo {
  margin: 0px auto;
}

.profileThumb {
  display: flex;
  margin-bottom: 30px;

  img {
    width: 80px;
    cursor: pointer;
    height: 80px;
    object-fit: fill;
    border-radius: 60px;
    background: gold;
  }

  .nameDetails {
    padding: 20px;
  }

  .photoEdit {
    position: relative;

    .icon2 {
      position: absolute;
      background: #333;
      border-radius: 60px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      bottom: 0;
      right: 0;
      cursor: pointer;

      input {
        width: 30px;
        height: 30px;
        border: 0;
        outline: none;
        visibility: inherit;
        position: absolute;
        left: 0;
        cursor: pointer;

        [type="file"] {
          height: 0;
          overflow: hidden;
          width: 0;
          background: transparent;
        }
      }

      svg {
        width: 16px;
      }
    }
  }
}

.financial-text {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}

.refIcon {
  font-size: 18px !important;
  margin-right: 5px !important;
}
.alignRowFinancil{
   display: flex;
   align-items: center;
}
.copyIcon{
  align-self: center;
  padding-left: 0;
}
.copyBtn{
  min-width: unset;
  padding: 0;
}

.btnEdit {
  font-size: 12px;
  background: $white;
  border: solid 1px $text-btn;
  border-radius: 60px;
  color: $text-btn;
  margin-right: 0;
  padding: 4px 10px;
  &:hover {
    background: $text-btn;
    border: solid 1px $text-btn;
    color: #ffffff;
    box-shadow: none;
    padding: 4px 10px;
    transform: translateY(0px);

  }
}

.card2{
      padding: 0 !important;
      border:0 !important ;
      box-shadow: none !important;
      .card-body{
        padding: 0;
    }
}
.selectMonthSplit
{display: flex;
align-items: center;}

/* responsive media quires */
@media screen and (min-width: 1601px) and (max-width: 1920px) {
  .form-check-input {
    width: 18px;
    height: 18px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  body {
    font-size: 14px;
  }

  .datatableGrid {
    height: calc(100vh - 150px);
    &.bank-transaction {
      height: calc(100vh - 170px);
  }
  }

  .menu-icon input[type="radio"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  h3 {
    font-size: 18px;
  }

  .sidebar-nav {
    .nav-item {
      .nav-link {
        font-size: 14px;
      }
    }
  }

  .form-select {
    font-size: 14px;
  }

  .cardBody {
    .cardHeader {
      // border-bottom: solid 1px #D9D9D9;
      padding: 6px 0px;
      margin-bottom: 5px;

      input[type="text"] {
        border: solid 1px #cdd4d9;
        padding: 4px 16px;
        border-radius: 60px;
        //margin-right: 10px;

        &:focus {
          border: solid 1px #cdd4d9;
        }

        &:hover {
          border: solid 1px #cdd4d9;
        }
      }
    }
  }

  ul.pagination {
    padding-right: 30px;
  }

  .offcanvas-header {
    padding: 5px 15px;
  }

  .addBox {
    overflow-y: auto;
    height: 90vh;
    overflow-x: hidden;
    padding: 0px;
  }

  .cardTable {
    table {
      tr {
        td {
          font-size: 13px;
        }
      }
    }
  }

  /* carousel */
  .modal-body {
    .carousel-item {
      height: 420px;
      overflow-y: auto;
    }
  }

  /* common button */
  .form-check-input {
    width: 16px;
    height: 16px;
  }

  .btn-primary {
    font-size: 14px;
    padding: 6px 20px;
  }

  .btn-light {
    font-size: 14px;
    padding: 6px 20px;
  }

  .menu-icon {
    font-size: 24px;
  }

  .width400 {
    width: 400px !important;
  }
}
.downloadPage {
  .card {
      .logo{
          padding: 40px;
          img{
              border: 0;
              width: 100%;
          }
      }
      h4{
          padding:0px 0px 20px 0px;  
      }
      padding: 30px;
      text-align: center;
      margin: 0px auto;
      max-width: 375px;
      /* box-shadow: -1px 4px 12px -8px rgba(0, 0, 0, 0.75); */
      border: 0;
      border-radius: 30px;
      background: #f7f7f7;
      .DownloadBtn{
          img{
              width:100%;
          }
      }
  }
}
.logo {
  text-align: center;
}

.forget-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light gray background */
}

.form-container {
  width: 400px; /* Adjust width as needed */
  padding: 20px;
  border: 1px solid #ccc; /* Gray border */
  border-radius: 10px;
  background-color: #fff; /* White background */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-with-button {
  position: relative;
}

.input-with-button .inside-button {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 10px; /* Adjust as needed */
  z-index: 1;
}

// New Coding Destails CSS START
.entriesWrapper {
  .entriesInner {
    position: relative;
    &::after {
      width: 5px;
      height: 100%;
      content: "";
      position: absolute;
      top: 0;
      left: -16px;
      background: var(--entrie-color);
      border-radius: 0 5px 5px 0;
    }
  }
  .seprator {
    position: relative;
    border-bottom: 1px solid #d4d4d4;
    margin: 10px 0;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: -16px;
      width: 16px;
      border-bottom: 1px solid #d4d4d4;
    }
    &::after{
      content: "";
      position: absolute;
      top: 0;
      right: -16px;
      width: 16px;
      border-bottom: 1px solid #d4d4d4;
    }
  } 
}
.entrie-menu-btn {
  position: relative;
  left: -12px;
}
.add-code-btn {
  button, 
  &:focus {
    color: #1976d2;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background: none !important;
    padding: 0;
    border: none;
    &:hover {
      color: #1976d2;
      box-shadow: none;
    }
  }
}
button.btn-blue-outline,
button.btn-blue-outline:hover,
button.btn-blue-outline:focus,
button.btn-blue-outline:active,
button.btn:first-child:active {
  background: transparent;
  color: #1976d2 !important;
  border-color: rgba(0, 0, 0, 0.1);
}
.btn-big {
  button {
    min-width: 120px;
    min-height: 50px;
  }
}
.support-btn {
  border: none;
  line-height: 0;
  background-color: transparent;
  color: var(--cui-btn-hover-bg);
  font-size: 35px;
}
button.prev-next-btn,
button.prev-next-btn:hover,
button.prev-next-btn:focus,
button.prev-next-btn:active,
button.prev-next-btn:first-child:active,
button.prev-next-btn:last-child:active  {
  padding: 0;
  display: block;
  background: transparent;
  color: grey !important;
  border: none;
  font-weight: 600;
}
button.prev-next-btn{
  &.arrow-top {
    svg {
      transform: rotate(-90deg);
    }
  }
  &.arrow-bottom {
    svg {
      transform: rotate(90deg);
    }
  }
}
button {
  &.verify-it-btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    background-color: #2a2a39;
    &:hover {
      background-color: #2a2a39;
      opacity: 0.8;
    }
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.wrap-cell {
  white-space: normal !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.2;
  margin-top: 6px;
}

.header-row {
  font-weight: bold;
}
.pageBreakRow{
 page-break-inside: avoid; 
}
.subtotal-row{
  background-color: #e6e6e6;//rgb(163, 160, 160);
  color: black;
  font-weight: bold;
}
.grandtotal-row{
  background-color: #e6e6e6;//rgb(163, 160, 160);
  color: black;
  font-weight: bold;
}
// Tab CSS START
.cstm-tab-wrapper {
  margin-top: 10px;
}
// .cstm-tab-wrapper ul {
//   border: none;
// }
.cstm-tab-wrapper ul li .nav-link {
  color: #2a2a39;
  opacity: 0.8;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.cstm-tab-wrapper ul li .nav-link:hover {
  opacity: 1;
}
.cstm-tab-wrapper ul li .nav-link.active {
  opacity: 1;
  color: #2a2a39;
}
.cstm-tab-wrapper ul li .nav-link,
.cstm-tab-wrapper ul li .nav-link:hover,
.cstm-tab-wrapper ul li .nav-link.active {
  // border: none;
}
// .cstm-tab-wrapper ul li .nav-link::after {
//   content: "";
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 0;
//   border-bottom: 2px solid #2a2a39;
//   transition: width 0.4s ease-in-out;
// }
// .cstm-tab-wrapper ul li .nav-link.active::after {
//   width: 100%;
// }


// Financial year select 
.financial-stack {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}
.financial-year-select .MuiSelect-select {
  padding-top: 5px;
  padding-bottom: 5px;
}

// Responsive
@media (min-width: 768px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: 0;
  }
}